<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <ValidationObserver
        ref="form"
      >
        <sobre-enviar-form
          :idsobre="routeParams.idsobre"
          @change-values="changeFormData"
        />
      </ValidationObserver>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import SobreEnviarForm from './components/SobreEnviarForm'
import Data from './SobreEnviarData'

export default {
  components: {
    SobreEnviarForm,
  },
  mixins: [formPageMixin],
  props: {
    viewRoute: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      ididioma: null,
    }
  },
  async created () {
    await this.initStore()
    await this.loadPage()
    this.submitMessage = this.$options.filters.linebreaksBr(`
      Enviando documentación...
      (este proceso puede demorar algunos segundos)
    `)
  },
  methods: {
    async loadPage () {
      this.title = 'Enviar sobre con documentación'
    },
    changeFormData (formData) {
      this.formData = formData
    },
    async submitForm () {
      const result = await Data.entregarSobre(
        this, this.formData, this.routeParams.idsobre
      )
      if (result) {
        this.$alert.showSnackbarSuccess('La documentación ha sido enviada')
      } else {
        this.$alert.showSnackbarError('Se ha producido algún error al realizar el envío.')
      }
      this.$appRouter.go(-1)
    }
  },
}
</script>
